import { saveAs } from "file-saver";

/**
 * @description save PDF and EXCEL files
 * @param bodyData: string
 * @param fileName: string
 * @param fileType: string
 * @param fileExt: string
 */
export const saveFile = (
  bodyData: string,
  fileName: string,
  fileType: string,
  fileExt: string
): void => {
  const binary = window.atob(bodyData.replace(/\s/g, ""));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const blob = new Blob([view], { type: fileType });
  saveAs(blob, fileName + fileExt);
};
