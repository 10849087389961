
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ReportSupervisorData from "@/data/payment";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { saveFile } from "@/core/services/FileSaverService";
import { useStore } from "vuex";
import moment from "moment";

export default defineComponent({
  name: "ReportSupervisor",
  setup() {
    const store = useStore();
    onMounted(async () => {
      setCurrentPageTitle("Хянан шалгагч /тайлангууд/");
    });
    const isu = computed(() => {
      return store.getters.getMbUser;
    });
    return {
      isu
    };
  },
  data() {
    return {
      loadButton: "off",
      registerno: "",
      monthValue:
        (new Date().getMonth() + 1).toString().length === 2
          ? (new Date().getMonth() + 1).toString()
          : "0" + (new Date().getMonth() + 1).toString(),
      months: ReportSupervisorData[0]["months"],
      yearValue: "2022",
      orgList: [],
      reportLog: [],
      reportLogLoading: true,
      orgValue: "",
      dateOfStart: "",
      dateOfEnd: "",
      years: [
        {
          yearValue: "2022",
          yearLabel: "2022"
        },
        {
          yearValue: "2021",
          yearLabel: "2021"
        }
      ]
    };
  },
  mounted() {
    if (!this.isu) {
      ElNotification({
        title: "Анхааруулга",
        message: "Хандах эрхгүй байна.",
        type: "warning",
        iconClass: "el-text-warning"
      });
    } else {
      this.getRequiredData();
    }
  },
  methods: {
    async getRequiredData() {
      this.reportLogLoading = true;
      await ApiService.get("reportsupervisor")
        .then(res => {
          const results = res.data;
          if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else {
            this.orgList = results.response.orglist;
            this.reportLog = results.response.reportlog;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Байгууллагын жагсаалт татхад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.reportLogLoading = false;
        });
    },
    async getReportTotalLoans() {
      this.loadButton = "on";
      await ApiService.get("reportindex", "?type=total_loans")
        .then(res => {
          const results = res.data;
          if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else {
            saveFile(
              results.response,
              "LoanBalanceByOrg",
              "application/vnd.ms-excel",
              ".xls"
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Тайлан татахад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadButton = "off";
        });
    },
    async getReportInvoiceReport() {
      this.loadButton = "on";
      const params = {
        type: "invoice_report",
        year: this.yearValue,
        month: this.monthValue
      };
      await ApiService.post("reportindex", { params })
        .then(res => {
          const results = res.data;
          if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else {
            saveFile(
              results.response,
              "InvoiceReport_" + this.yearValue + this.monthValue,
              "application/vnd.ms-excel",
              ".xls"
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Тайлан татахад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadButton = "off";
        });
    },
    async getReportInvoicePayment() {
      if (
        this.orgValue == "" ||
        this.dateOfStart == "" ||
        this.dateOfEnd == ""
      ) {
        ElNotification({
          title: "Анхааруулга",
          message: "Аль нэг утга хоосон байна.",
          type: "warning",
          iconClass: "el-text-warning"
        });
        return;
      }
      this.loadButton = "on";

      const fromdate = moment(this.dateOfStart).format("YYYY-MM-DD");
      const todate = moment(this.dateOfEnd).format("YYYY-MM-DD");
      const params = {
        type: "SupplyLoanList",
        orgcode: this.orgValue,
        fromdate: fromdate,
        todate: todate
      };
      await ApiService.post("reportindex", { params })
        .then(res => {
          const results = res.data;
          if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else {
            saveFile(
              results.response,
              "supplyLoanList_" + this.orgValue,
              "application/vnd.ms-excel",
              ".xls"
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Тайлан татахад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadButton = "off";
        });
    },
    async getReportReportInquiry() {
      if (this.registerno === "") {
        ElNotification({
          title: "Анхааруулга",
          message: "Хоосон байна.",
          type: "warning",
          iconClass: "el-text-warning"
        });
        return;
      }
      this.loadButton = "on";
      const params = {
        type: "reportInquiry",
        registerno: this.registerno
      };
      await ApiService.post("reportindex", { params })
        .then(res => {
          const results = res.data;
          if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else {
            saveFile(
              results.response,
              "InvoiceReport_" + this.registerno,
              "application/vnd.ms-excel",
              ".xls"
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Тайлан татахад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadButton = "off";
        });
    }
  }
});
