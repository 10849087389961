const PaymentData: object = [
  {
    months: [
      {
        monthValue: "01",
        monthLabel: "1 сар"
      },
      {
        monthValue: "02",
        monthLabel: "2 сар"
      },
      {
        monthValue: "03",
        monthLabel: "3 сар"
      },
      {
        monthValue: "04",
        monthLabel: "4 сар"
      },
      {
        monthValue: "05",
        monthLabel: "5 сар"
      },
      {
        monthValue: "06",
        monthLabel: "6 сар"
      },
      {
        monthValue: "07",
        monthLabel: "7 сар"
      },
      {
        monthValue: "08",
        monthLabel: "8 сар"
      },
      {
        monthValue: "09",
        monthLabel: "9 сар"
      },
      {
        monthValue: "10",
        monthLabel: "10 сар"
      },
      {
        monthValue: "11",
        monthLabel: "11 сар"
      },
      {
        monthValue: "12",
        monthLabel: "12 сар"
      }
    ],
    year: [
      {
        yearValue: "2022",
        yearLabel: "2022"
      },
      {
        yearValue: "2021",
        yearLabel: "2021"
      },
      {
        yearValue: "2020",
        yearLabel: "2020"
      },
      {
        yearValue: "2019",
        yearLabel: "2019"
      },
      {
        yearValue: "2018",
        yearLabel: "2018"
      },
      {
        yearValue: "2017",
        yearLabel: "2017"
      },
      {
        yearValue: "2016",
        yearLabel: "2016"
      },
      {
        yearValue: "2015",
        yearLabel: "2015"
      },
      {
        yearValue: "2014",
        yearLabel: "2014"
      }
    ]
  }
];

export default PaymentData;
